import './main.pcss';
import './icons.svg';

import './js/parking';
import './js/default';
import './js/animations';

import GLightbox from 'glightbox';
import LazyLoad from 'vanilla-lazyload';

// eslint-disable-next-line no-unused-vars
const lightbox = GLightbox();

const ll = new LazyLoad({
  elements_selector: '.lazy-load',
});
if (ll) {
  ll.update();
}
