import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const cardsAnimation = (elements) => {
  gsap.to(elements, {
    autoAlpha: 1,
    y: 0,
    stagger: 0.15,
  });
};

const triggerOffers = () => {
  ScrollTrigger.batch('.offers-list .card-outer', {
    onEnter: (elems) => { cardsAnimation(elems); },
    once: true,
    start: () => `top ${window.innerHeight - 100}px`,
  });
};
triggerOffers();

ScrollTrigger.batch('.subscriptions-list .subscription-card', {
  onEnter: (elems) => { cardsAnimation(elems); },
  once: true,
  start: () => `top ${window.innerHeight - 100}px`,
});

if (window.htmx) {
  window.htmx.on('htmx:afterSwap', (event) => {
    const { id } = event.target;
    if (id === 'offers') {
      triggerOffers();
    }
  });
}
