import Masonry from 'masonry-layout';
import Splide from '@splidejs/splide';
import { Intersection } from '@splidejs/splide-extension-intersection';

const bodyObserver = new IntersectionObserver((cb) => {
  if (cb[0].isIntersecting) {
    document.body.classList.remove('entered');
  } else {
    document.body.classList.add('entered');
  }
});
bodyObserver.observe(document.querySelector('.body-observer'));

const gridMasonry = document.querySelector('.grid-masonry');
if (gridMasonry) {
  const msnry = new Masonry(gridMasonry, {
    itemSelector: '.card',
    columnWidth: '.grid-sizer',
    percentPosition: true,
  });

  msnry.layout();
}

/* ---------------------------
 * sliders
 --------------------------- */
const heroSlider = document.querySelector('.splide.splide-hero');
if (heroSlider) {
  const images = Array.from(heroSlider.querySelectorAll('.splide__slide img'));
  const { interval, speed } = heroSlider.dataset;

  let currentSlide = 0;
  const splide = new Splide(heroSlider, {
    type: 'fade',
    rewind: true,
    autoplay: 'pause',
    intersection: {
      inView: {
        autoplay: true,
      },
      outView: {
        autoplay: false,
      },
    },
    perPage: 1,
    interval: +interval,
    speed: +speed,
    easing: 'ease-in-out',
    lazyLoad: true,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
  });

  splide.mount({ Intersection });

  splide.on('moved', (newIndex, prevIndex) => {
    currentSlide = newIndex;
    images[newIndex].classList.add('animate');
    window.setTimeout(() => {
      images[prevIndex].classList.remove('animate');
    }, +speed);
  });

  splide.on('intersection:in', () => {
    images[currentSlide].classList.add('animate');
  });

  splide.on('intersection:out', () => {
    images.forEach((img) => {
      img.classList.remove('animate');
    });
  });
}

const testimonialsSlider = document.querySelectorAll('.testimonials-slider .splide');
testimonialsSlider.forEach((slider) => {
  const splide = new Splide((slider), {
    pagination: true,
    autoplay: 'pause',
    intersection: {
      inView: {
        autoplay: true,
      },
      outView: {
        autoplay: false,
      },
    },
  });
  splide.mount({ Intersection });
});

function arrowsPosition(splide) {
  if (!splide.root.classList.contains('offers-slider')) {
    return;
  }
  const headerHeight = splide.root.querySelector('.card header').clientHeight;
  const arrows = splide.root.querySelectorAll('.splide__arrow');
  arrows.forEach((arrow) => {
    arrow.style.top = `${headerHeight / 2}px`;
  });
}

const offersSlider = document.querySelectorAll('.offers-slider.splide, .images-slider.splide');
offersSlider.forEach((slide) => {
  let perPage = {
    perPage: 3,
    breakpoints: {
      1023: {
        perPage: 2,
      },
      767: {
        perPage: 1,
      },
    },
  };
  if (slide.dataset.perPage === '4') {
    perPage = {
      perPage: 4,
      breakpoints: {
        1279: {
          perPage: 3,
        },
        767: {
          perPage: 2,
        },
        639: {
          perPage: 1,
        },
      },
    };
  }

  const splide = new Splide(slide, {
    ...perPage,
    gap: '2rem',
    rewind: false,
    pagination: false,
    lazyLoad: true,
  });
  splide.on('ready', () => { arrowsPosition(splide); });
  splide.on('resized', () => { arrowsPosition(splide); });
  splide.mount();
});

/* ---------------------------
 * toggle accordions
 --------------------------- */
const accordionTitles = document.querySelectorAll('.accordion-title');

function toggleAccordion(ev) {
  const current = ev.currentTarget;
  const { classList } = current;
  const sibling = current.nextElementSibling;
  if (classList.contains('open')) {
    classList.remove('open');
    sibling.style.maxHeight = 0;
  } else {
    classList.add('open');
    sibling.style.maxHeight = `${sibling.scrollHeight}px`;
  }
}

accordionTitles.forEach((elem) => {
  elem.addEventListener('click', toggleAccordion);
  if (elem.classList.contains('has-sub-nav') && elem.classList.contains('open')) {
    const next = elem.nextElementSibling;
    next.style.maxHeight = `${next.scrollHeight}px`;
  }
});

/* ---------------------------
 * toggle site search
 --------------------------- */
const siteSearchContainer = document.querySelector('.site-search-container');
const openSiteSearch = document.querySelector('.open-site-search');
const closeSiteSearch = document.querySelector('.close-site-search');
let searchSiteInput = null;

if (siteSearchContainer) {
  searchSiteInput = siteSearchContainer.querySelector('input[name=search]');
  openSiteSearch.addEventListener('click', () => {
    siteSearchContainer.style.maxHeight = '100vh';
  });
  closeSiteSearch.addEventListener('click', () => {
    siteSearchContainer.style.maxHeight = 0;
    searchSiteInput.value = '';
  });
}

/* ---------------------------
 * toggle mobile nav
 --------------------------- */
const burger = document.querySelector('.toggle-mobile-nav');
if (burger) {
  burger.addEventListener('click', () => {
    document.body.classList.toggle('mobil-nav-open');
  });
}
document.querySelectorAll('[data-fit]').forEach((elem) => {
  const elemWidth = elem.getBoundingClientRect().height;
  const parentWidth = elem.parentNode.getBoundingClientRect().width;
  elem.style.transformOrigin = 'top left';
  elem.style.transform = `scale(${parentWidth / elemWidth})`;
});

/* ---------------------------
 * resizing
 --------------------------- */
let resizeTimer;
window.addEventListener('resize', () => {
  window.clearTimeout(resizeTimer);
  resizeTimer = window.setTimeout(() => {
    const openedAccordions = [...accordionTitles].filter((title) => title.classList.contains('open'));
    openedAccordions.forEach((elem) => {
      elem.nextElementSibling.style.maxHeight = `${elem.nextElementSibling.scrollHeight}px`;
    });
  }, 250);
});
